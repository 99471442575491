// @flow

import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import styled from 'styled-components'

import {
  Absolute,
  Flex,
  Box,
  Grid,
  Container,
  Heading,
  Nav,
  Layout,
  Relative,
  Section,
} from '../components'

import { Scissors } from '../icons/barbershop'

import { gradient } from '../utils'

function PriceList({ items }: { items: Array<any> }): React.Node {
  return (
    <Box as="ul" pl={0} my={0} mx={0}>
      {items.map(item => (
        <Flex as="li" justifyContent="space-between" key={item.name}>
          {item.name} <strong>${item.price}</strong>
        </Flex>
      ))}
    </Box>
  )
}

function ServicesPage({ allServicesJson }: any): React.Node {
  let { edges } = allServicesJson

  return (
    <Layout>
      <Section>
        <Container maxWidth={1200}>
          <Relative>
            <Heading as="h1" mt={40} mb={20} textAlign="left">
              Services
            </Heading>
            <Absolute top={-30} right={0}>
              <Scissors width="5em" height="5em" />
            </Absolute>
          </Relative>
          <Flex justifyContent="flex-start" alignItems="center">
            {edges.map(({ node }) => {
              return (
                <Box
                  width={['320px', null, 1]}
                  p={[15, 15, 20]}
                  mb={20}
                  key={node.id}
                >
                  <PriceList items={node.items} />
                </Box>
              )
            })}
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

const Services = () => <StaticQuery query={query} render={ServicesPage} />

export default Services

export const query = graphql`
  query ServicesQuery {
    allServicesJson {
      edges {
        node {
          id
          type
          items {
            name
            price
          }
        }
      }
    }
  }
`
